// utils
import React, { useState } from "react";
import { useScrollTop } from "../assets/customHooks/useScrollTop";
import { loadInputValues, saveInputValues, disableBodyScroll, embedFormValue } from "../assets/funcs/commonFuncs.jsx";
import parse from "html-react-parser";

// components
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import Section_title from "../components/common/Section_title.jsx";
import BsToast from "../components/common/BsToast.jsx";
import Loading from "../components/uncommon/Loading.jsx";
import { fetchURL } from "../assets/customHooks/fetchURL.js";
import { useEffect } from "react";

// 初期値
const LOCAL_STORAGE_KEY = "Form_contact_remind";
// 初期値として保存しないフォームデータ
const UNSAVE_VALUE_NAMES = ["message"];

// お問い合わせフォーム
export default function Form_contact() {
    useScrollTop();

    // 初期値
    const [initialValueObj, setInitialValueObj] = useState({});

    // フォームの内容
    const [isModalOpen, setModalOpen] = useState(false); // モーダルの表示状態を管理
    const [formData, setFormData] = useState({}); // フォームデータを保存

    // 送信
    const [isSending, setIsSending] = useState(false);
    const [sendResult, setSendResult] = useState("");

    // 電話番号のフォーマット関数
    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // 数字以外を削除
        const onlyNums = value.replace(/[^\d]/g, "");

        // 3桁-4桁-4桁の形式に変換
        if (onlyNums.length <= 3) return onlyNums;
        if (onlyNums.length <= 7) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7, 11)}`;
    };

    const validateForm = (values) => {
        const errors = {};

        // メールアドレスの一致を確認
        if (values.mailAddress !== values.mailAddress_check) {
            errors.mailAddress_check = "メールアドレスが一致しません。";
        }

        // 電話番号のバリデーション
        if (values.phoneSmartNumber) {
            const onlyNums = values.phoneNumber.replace(/[^\d]/g, "");
            if (onlyNums.length !== 11) {
                errors.phoneNumber = "XXX-XXXX-XXXXの形式で入力してください。";
            }
        }

        return errors;
    };

    const onSubmit = (values) => {
        console.log(values);
        saveInputValues(LOCAL_STORAGE_KEY, values, UNSAVE_VALUE_NAMES);
        setFormData(values);
        disableBodyScroll(true);
        setModalOpen(true);
    };

    // モーダルを閉じる処理
    const closeModal = () => {
        disableBodyScroll(false);
        setModalOpen(false);
    };

    // トースト
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState([]);
    function toggleToast(message) {
        setToastMessage(message);
        setShowToast(!showToast);
    }

    useEffect(() => {
        setInitialValueObj(loadInputValues(LOCAL_STORAGE_KEY));
    }, []);

    return (
        <div className="page Form" id="Form_contact">
            <main>
                <Section_title eng="Contact Form" jp="お問い合わせフォーム" colorClass={"green"} />
                <div className="wrapper">
                    <Form
                        onSubmit={onSubmit}
                        validate={validateForm}
                        initialValues={initialValueObj}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <p className="req item">件名</p>
                                    <Field
                                        name="contactAbout"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="unreq item">お名前 (ハンドル名可)</p>
                                    <Field name="yourName" component="input" type="text" className="form-control" />
                                </div>

                                <div>
                                    <p className="req item">会社名</p>
                                    <Field name="companyName" component="input" type="text" className="form-control" required/>
                                </div>

                                <div>
                                    <p className="req item">担当者名</p>
                                    <Field
                                        name="contactorName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">メッセージ</p>
                                    <Field name="message" component="textarea" className="form-control" required />
                                </div>

                                <div>
                                    <p className="req item">回答の有無</p>
                                    <div className="form-check">
                                        <Field
                                            name="answerRequired"
                                            component="input"
                                            type="radio"
                                            value="必要"
                                            className="form-check-input"
                                            required
                                        />
                                        <label className="form-check-label">必要</label>
                                    </div>
                                    <div className="form-check">
                                        <Field
                                            name="answerRequired"
                                            component="input"
                                            type="radio"
                                            value="不要"
                                            className="form-check-input"
                                            required
                                        />
                                        <label className="form-check-label">不要</label>
                                    </div>
                                </div>

                                {values.answerRequired === "必要" && (
                                    <>
                                        <div>
                                            <p className="req item">回答方法</p>
                                            <div className="form-check">
                                                <Field
                                                    name="answerMethod"
                                                    component="input"
                                                    type="radio"
                                                    value="メール"
                                                    className="form-check-input"
                                                    required
                                                />
                                                <label className="form-check-label">メール</label>
                                            </div>
                                            <div className="form-check">
                                                <Field
                                                    name="answerMethod"
                                                    component="input"
                                                    type="radio"
                                                    value="電話"
                                                    className="form-check-input"
                                                    required
                                                />
                                                <label className="form-check-label">電話</label>
                                            </div>
                                            <div className="form-check">
                                                <Field
                                                    name="answerMethod"
                                                    component="input"
                                                    type="radio"
                                                    value="FAX"
                                                    className="form-check-input"
                                                    required
                                                />
                                                <label className="form-check-label">FAX</label>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {values.answerRequired === "必要" && values.answerMethod === "メール" && (
                                    <>
                                        <div>
                                            <p className="req item">メールアドレス</p>
                                            <Field
                                                name="mailAddress"
                                                component="input"
                                                type="email"
                                                className="form-control"
                                                required
                                            />
                                            <Field name="mailAddress_check" component="input" type="email" required>
                                                {({ input, meta }) => (
                                                    <div>
                                                        <input
                                                            className="form-control mt-1"
                                                            placeholder="確認用"
                                                            {...input}
                                                        />
                                                        {meta.error && meta.touched && (
                                                            <span style={{ color: "red" }}>{meta.error}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </>
                                )}

                                {values.answerRequired === "必要" && values.answerMethod === "電話" && (
                                    <>
                                        <div>
                                            <p className="req item">電話番号</p>
                                            <Field
                                                name="phoneNumber"
                                                component="input"
                                                type="text"
                                                required
                                                parse={formatPhoneNumber}
                                            >
                                                {({ input, meta }) => (
                                                    <div>
                                                        <input className="form-control" {...input} />
                                                        {meta.error && meta.touched && (
                                                            <span style={{ color: "red" }}>{meta.error}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div>
                                            <p className="req item">携帯番号</p>
                                            <Field
                                                name="phoneSmartNumber"
                                                component="input"
                                                type="text"
                                                required
                                                parse={formatPhoneNumber}
                                            >
                                                {({ input, meta }) => (
                                                    <div>
                                                        <input className="form-control" {...input} />
                                                        {meta.error && meta.touched && (
                                                            <span style={{ color: "red" }}>{meta.error}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </>
                                )}

                                {values.answerRequired === "必要" && values.answerMethod === "FAX" && (
                                    <>
                                        <div>
                                            <p className="req item">FAX番号</p>
                                            <Field
                                                name="faxNumber"
                                                component="input"
                                                type="number"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                    </>
                                )}

                                <div>
                                    <button className="link_btn center mt-3" type="submit">
                                        入力内容を確認
                                    </button>
                                </div>
                            </form>
                        )}
                    />
                    {/* 内容確認モーダル */}
                    {isModalOpen && (
                        <div className="checkingModal">
                            <div className="checkingModalContent">
                                <p className="title">入力内容の確認</p>
                                <div className="inputedValues">
                                    <label>件名</label>
                                    <p className="inputedValue">{formData.contactAbout}</p>
                                    {formData.yourName ? (
                                        <>
                                            <label>お名前 (ハンドル名可)</label>
                                            <p className="inputedValue">{formData.yourName}</p>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {formData.companyName ? (
                                        <>
                                            <label>会社名</label>
                                            <p className="inputedValue">{formData.companyName}</p>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {formData.contactorName ? (
                                        <>
                                            <label>担当者名</label>
                                            <p className="inputedValue">{formData.contactorName}</p>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <label>メッセージ</label>
                                    <p className="inputedValue">{formData.message}</p>
                                    <label>回答の有無</label>
                                    <p className="inputedValue">{formData.answerRequired}</p>
                                    {formData.answerRequired === "必要" && (
                                        <>
                                            <label>回答方法</label>
                                            <p className="inputedValue">{formData.answerMethod}</p>
                                            {formData.answerMethod === "メール" && (
                                                <>
                                                    <label>メールアドレス</label>
                                                    <p className="inputedValue">{formData.mailAddress}</p>
                                                </>
                                            )}
                                            {formData.answerMethod === "電話" && (
                                                <>
                                                    <label>電話番号</label>
                                                    <p className="inputedValue">{formData.phoneNumber}</p>
                                                    <label>携帯番号</label>
                                                    <p className="inputedValue">{formData.phoneSmartNumber}</p>
                                                </>
                                            )}
                                            {formData.answerMethod === "FAX" && (
                                                <>
                                                    <label>FAX番号</label>
                                                    <p className="inputedValue">{formData.faxNumber}</p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="bottomBlock mt-3">
                                    <div className="isSending">
                                        {isSending && (
                                            <>
                                                <Loading height="38px" />
                                                <p className="ms-3">送信中...送信完了まで待ちください。</p>
                                            </>
                                        )}
                                        {sendResult !== "" && <p className="sendResult">{parse(sendResult)}</p>}
                                    </div>
                                    <div className="btns">
                                        {isSending ? (
                                            // 送信中
                                            <></>
                                        ) : sendResult !== "" ? (
                                            // 送信完了後
                                            <Link className="link_btn orange mt-2" onClick={closeModal} to="/">
                                                TOPに戻る
                                            </Link>
                                        ) : (
                                            // 送信前
                                            <>
                                                <p className="link_btn orange" onClick={closeModal}>
                                                    戻る
                                                </p>
                                                <p
                                                    className="link_btn green ms-4 me-2"
                                                    onClick={() => {
                                                        console.log("メールを送信中...");
                                                        sendForm(formData, setIsSending);
                                                        setIsSending(true);
                                                    }}
                                                >
                                                    送信する
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <BsToast show={showToast} onClose={toggleToast} messages={toastMessage} />
        </div>
    );

    async function sendForm(formData, setIsSending) {
        const SUBJECT = `お問い合わせフォーム`;

        let yourName = embedFormValue(formData.yourName, "お名前(ハンドル名可)");
        let companyName = embedFormValue(formData.companyName, "会社名");
        let contactorName = embedFormValue(formData.contactorName, "担当者名");

        let answerMethod = "";
        if (formData.answerRequired === "必要") {
            answerMethod = embedFormValue(formData.answerMethod, "回答方法");
            switch (formData.answerMethod) {
                case "メール":
                    answerMethod += embedFormValue(formData.mailAddress, "メールアドレス");
                    break;
                case "電話":
                    answerMethod += embedFormValue(formData.phoneNumber, "電話番号");
                    answerMethod += embedFormValue(formData.phoneSmartNumber, "携帯番号");
                    break;
                case "FAX":
                    answerMethod += embedFormValue(formData.faxNumber, "FAX番号");
                    break;
                default:
                    break;
            }
        }

        const buildBody = `
            <html lang='ja'>
                <head>
                    <meta charset='UTF-8'>
                </head>
                <body>
                    <main>
                        <h2>${SUBJECT}</h2>
                        <label style="padding: 0; font-weight: bold;">件名</label>
                        <p style="margin:0;margin-bottom:10px;">${formData.contactAbout}</p>
                        ${yourName}
                        ${companyName}
                        ${contactorName}
                        <label style="padding: 0; font-weight: bold;">メッセージ</label>
                        <p style="margin:0;margin-bottom:10px;">${formData.message}</p>
                        <label style="padding: 0; font-weight: bold;">回答の有無</label>
                        <p style="margin:0;margin-bottom:10px;">${formData.answerRequired}</p>
                        ${answerMethod}
                    </main>
                </body>
            </html>`;

        const formDatas = new FormData();
        formDatas.append("subject", SUBJECT);
        formDatas.append("body", buildBody);

        try {
            const res = await fetch(`${fetchURL}/sendMail.php`, {
                method: "POST",
                body: formDatas,
            });
            const response = await res.text();

            if (!res.ok) {
                // ステータスコードが200以外の場合
                setSendResult(response);
                throw new Error(response);
            }
            setSendResult(response);
        } catch (error) {
            console.error("エラー:", error);
            setSendResult(error.message);
        } finally {
            setIsSending(false);
        }
    }
}
