import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "tippy.js/dist/tippy.css";
import "react-medium-image-zoom/dist/styles.css";

createRoot(document.getElementById("root")).render(<App/>);
