// utils
import React, { useEffect, useState } from "react";
import {
    loadInputValues,
    saveInputValues,
    dlHandler,
    getParam,
    dateFormater,
    disableBodyScroll,
    embedFormValue,
} from "../assets/funcs/commonFuncs.jsx";
import { useScrollTop } from "../assets/customHooks/useScrollTop";
import parse from "html-react-parser";

// components
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import Section_title from "../components/common/Section_title";
import BsToast from "../components/common/BsToast.jsx";
import Loading from "../components/uncommon/Loading.jsx";
import { fetchURL } from "../assets/customHooks/fetchURL.js";

// 初期値
const LOCAL_STORAGE_KEY = "Form_join_remind";
// 保存しないフィールド名
const UNSAVE_VALUE_NAMES = ["auctionName", "opinions", "entryReservationDate", "tourReservationDate", "isFirstTime"];

const MAX_FILES = 5; // 最大枚数
const MAX_SIZE = 15 * 1024 * 1024; // 1枚当たりの最大サイズ

// 入会フォーム
export default function Form_join({ allSchedules }) {
    useScrollTop();

    // 初期値
    const [initialValueObj, setInitialValueObj] = useState({});

    const [auctionName, setAuctionName] = useState(""); // urlパラメータのオークションネーム
    const [entryDateList, setEntryDateList] = useState([]); // 参加可能な日付のリスト

    // フォームの内容
    const [isModalOpen, setModalOpen] = useState(false); // モーダルの表示状態を管理
    const [formData, setFormData] = useState({}); // フォームデータを保存

    const [attachedFile, setAttachedFile] = useState(null); // 出品票
    const [attachedFileName, setAttachedFileName] = useState(null); // 選択されたファイル名
    const [attachedFileError, setAttachedFileError] = useState("ファイルが選択されていません");

    const [attachedImages, setAttachedImages] = useState(null); // 許可証の画像
    const [attachedImagesError, setAttachedImagesError] = useState("ファイルが選択されていません");

    // 送信
    const [isSending, setIsSending] = useState(false);
    const [sendResult, setSendResult] = useState("");

    // ファイルが選択された時の処理
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // ファイルが1つで、拡張子がxlsxであるかをチェック
        if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
            setAttachedFile(selectedFile);
            setAttachedFileName(selectedFile.name);
            setAttachedFileError("");
        } else {
            setAttachedFile(null);
            setAttachedFileError("ファイルが選択されていません");
        }
    };

    // 許可証の画像が選択された時の処理
    const handleImagesChange = (event) => {
        const selectedFiles = event.target.files;

        if (selectedFiles.length > MAX_FILES) {
            event.target.value = "";
            setAttachedImages(null);
            setAttachedImagesError(`最大${MAX_FILES}枚まで選択できます`);
            return;
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            if (selectedFiles[i].size > MAX_SIZE) {
                event.target.value = "";
                setAttachedImages(null);
                setAttachedImagesError(`1枚当たりのファイルサイズは最大15MBです`);
                return;
            }
        }

        setAttachedImages(selectedFiles);
        setAttachedImagesError("");
    };

    // 電話番号のフォーマット関数
    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // 数字以外を削除
        const onlyNums = value.replace(/[^\d]/g, "");

        // 3桁-4桁-4桁の形式に変換
        if (onlyNums.length <= 3) return onlyNums;
        if (onlyNums.length <= 7) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7, 11)}`;
    };

    const validateForm = (values) => {
        const errors = {};

        // メールアドレスの一致を確認
        if (values.mailAddress !== values.mailAddress_check) {
            errors.mailAddress_check = "メールアドレスが一致しません。";
        }

        // 電話番号のバリデーション
        if (!values.phoneNumber) {
            errors.phoneNumber = "この項目は必須です";
        } else {
            const onlyNums = values.phoneNumber.replace(/[^\d]/g, "");
            if (onlyNums.length !== 11) {
                errors.phoneNumber = "XXX-XXXX-XXXXの形式で入力してください。";
            }
        }

        if (values.knewReason?.length === 0) {
            errors.knewReason = "この項目は必須です";
        }

        return errors;
    };

    const onSubmit = (values) => {
        console.log(values);
        saveInputValues(LOCAL_STORAGE_KEY, values, UNSAVE_VALUE_NAMES);
        setFormData(values);
        disableBodyScroll(true);
        setModalOpen(true);
    };

    // モーダルを閉じる処理
    const closeModal = () => {
        disableBodyScroll(false);
        setModalOpen(false);
    };

    // トースト
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState([]);
    function toggleToast(message) {
        setToastMessage(message);
        setShowToast(!showToast);
    }

    useEffect(() => {
        setInitialValueObj(loadInputValues(LOCAL_STORAGE_KEY));

        const auctionName = getParam("auctionName");
        if (auctionName) {
            setInitialValueObj((prevState) => ({
                ...prevState,
                auctionName: auctionName,
            }));
            setAuctionName(auctionName);
        } else {
            console.warn("auctionNameが取得できませんでした");
        }

        const convAuctionName = {
            zero: "zeroMarketSchedules",
            hb: "hbMarketSchedules",
        };

        // 参加可能日時リストを取得
        const EntryDateList = allSchedules[convAuctionName[auctionName]];
        EntryDateList.sort((a, b) => new Date(a.date) - new Date(b.date));
        setEntryDateList(EntryDateList);
    }, [allSchedules]);

    return (
        <div className="page Form" id="Form_entry">
            <main>
                <Section_title
                    eng={`${auctionName.toUpperCase()} Membership Form`}
                    jp={`${auctionName.toUpperCase()}入会フォーム`}
                    colorClass="green"
                />
                <div className="wrapper">
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialValueObj}
                        validate={validateForm}
                        render={({ handleSubmit, values, errors }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="auctionName" component="input" type="hidden" initialValue={auctionName} />
                                <div>
                                    <p className="req item">見学を希望しますか？</p>
                                    <Field name="tourReservation" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="希望します">希望します</option>
                                        <option value="希望しません">希望しません</option>
                                    </Field>
                                </div>
                                {values.tourReservation === "希望します" && (
                                    <>
                                        <div>
                                            <p className="req item">見学希望日</p>
                                            <Field
                                                name="tourReservationDate"
                                                component="select"
                                                className="form-select"
                                                required
                                            >
                                                <option value=""></option>
                                                {entryDateList.map((entryDate, index) => {
                                                    const date = dateFormater(entryDate.date);
                                                    return (
                                                        <option key={index} value={date}>
                                                            {date}
                                                        </option>
                                                    );
                                                })}
                                            </Field>
                                        </div>
                                        <div>
                                            <p className="req item">見学人数</p>
                                            {["2名", "3名", "4名以上"].includes(values.tourReservationPersons) && (
                                                <p style={{ color: "red" }}>2名以上の場合は従業員許可が必要です。</p>
                                            )}
                                            <Field
                                                name="tourReservationPersons"
                                                component="select"
                                                className="form-select"
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="1名">1名</option>
                                                <option value="2名">2名</option>
                                                <option value="3名">3名</option>
                                                <option value="4名以上">4名以上</option>
                                            </Field>
                                        </div>
                                    </>
                                )}

                                <div>
                                    <p className="req item">参加の予約を希望しますか？</p>
                                    <Field name="entryReservation" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="希望します">希望します</option>
                                        <option value="希望しません">希望しません</option>
                                    </Field>
                                </div>
                                {values.entryReservation === "希望します" && (
                                    <>
                                        <div>
                                            <p className="req item">参加希望日</p>
                                            <Field
                                                name="entryReservationDate"
                                                component="select"
                                                className="form-select"
                                                required
                                            >
                                                <option value=""></option>
                                                {entryDateList.map((entryDate, index) => {
                                                    const date = dateFormater(entryDate.date);
                                                    return (
                                                        <option key={index} value={date}>
                                                            {date}
                                                        </option>
                                                    );
                                                })}
                                            </Field>
                                        </div>
                                        <div>
                                            <p className="req item">参加人数</p>
                                            {["2名", "3名", "4名以上"].includes(values.entryReservationPersons) && (
                                                <p style={{ color: "red" }}>2名以上の場合は従業員許可が必要です。</p>
                                            )}
                                            <Field
                                                name="entryReservationPersons"
                                                component="select"
                                                className="form-select"
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="1名">1名</option>
                                                <option value="2名">2名</option>
                                                <option value="3名">3名</option>
                                                <option value="4名以上">4名以上</option>
                                            </Field>
                                        </div>
                                    </>
                                )}

                                <div>
                                    <p className="req item">出品の有無</p>
                                    <Field name="sellHope" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="無し">無し</option>
                                        <option value="委託">委託</option>
                                        <option value="有り">有り</option>
                                    </Field>
                                </div>
                                {["有り", "委託"].includes(values.sellHope) && (
                                    <>
                                        <div>
                                            <p className="req item">出品数量</p>
                                            <Field
                                                name="sellAmount"
                                                component="input"
                                                type="number"
                                                min="1"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <p className="req item">ご出品内容</p>
                                            <Field
                                                name="sellDetails"
                                                component="textarea"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <p className="req item">出品票アップロード</p>
                                            <p style={{ fontSize: "15px" }}>
                                                出品票ダウンロードは
                                                <span
                                                    className="color_link"
                                                    onClick={() => {
                                                        dlHandler("shuppinhyou");
                                                    }}
                                                >
                                                    こちら
                                                </span>
                                            </p>
                                            <input
                                                name="file"
                                                type="file"
                                                className="form-control"
                                                accept=".xlsx"
                                                required
                                                onChange={handleFileChange}
                                            />
                                            {attachedFileError !== "" && (
                                                <p style={{ color: "red" }}>{attachedFileError}</p>
                                            )}
                                        </div>
                                    </>
                                )}

                                <div>
                                    <p className="req item">社名・屋号</p>
                                    <Field
                                        name="companyName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        placeholder="古物商許可証に記載の社名・屋号と同様のものをご記載ください"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">メールアドレス</p>
                                    <Field
                                        name="mailAddress"
                                        component="input"
                                        type="email"
                                        className="form-control"
                                        required
                                    />
                                    <Field name="mailAddress_check" component="input" type="email" required>
                                        {({ input, meta }) => (
                                            <div>
                                                <input className="form-control mt-1" placeholder="確認用" {...input} />
                                                {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">代表者名</p>
                                    <Field
                                        name="representativeName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">郵便番号</p>
                                    <Field
                                        name="postalCode"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        placeholder="例: 123-4567"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">住所</p>
                                    <Field
                                        name="address"
                                        component="input"
                                        type="address"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">電話番号</p>
                                    <Field
                                        name="phoneNumber"
                                        component="input"
                                        type="text"
                                        placeholder="000-0000-0000"
                                        parse={formatPhoneNumber}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <input className="form-control" {...input} />
                                                {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">許可証の発行都道府県</p>
                                    <Field
                                        name="permissionPrefectures"
                                        component="select"
                                        className="form-select"
                                        data-toggle="dropdown"
                                        required
                                    >
                                        <option value=""></option>
                                        {getPrefectures().map((prefecture, index) => {
                                            return (
                                                <option value={prefecture} key={index}>
                                                    {prefecture}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">許可証番号</p>
                                    <Field
                                        name="permissionNumber"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">許可証アップロード</p>
                                    <input
                                        name="file"
                                        type="file"
                                        className="form-control"
                                        multiple
                                        accept="image/*"
                                        required
                                        onChange={handleImagesChange}
                                    />
                                    {attachedImagesError !== "" && (
                                        <p style={{ color: "red" }}>{attachedImagesError}</p>
                                    )}
                                </div>

                                <div>
                                    <p className="req item">ご参加担当者名</p>
                                    <Field
                                        name="contactorName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">ご担当者の携帯電話番号</p>
                                    <Field
                                        name="contactorPhoneNumber"
                                        component="input"
                                        type="text"
                                        placeholder="000-0000-0000"
                                        parse={formatPhoneNumber}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <input className="form-control" {...input} />
                                                {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">適格請求事業者ですか？</p>
                                    <Field name="invoice" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="はい">はい</option>
                                        <option value="いいえ">いいえ</option>
                                    </Field>
                                </div>
                                {values.invoice === "はい" && (
                                    <>
                                        <div>
                                            <p className="req item">登録番号</p>
                                            <Field
                                                name="invoiceNumber"
                                                component="input"
                                                type="number"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                    </>
                                )}

                                <div>
                                    <p className="unreq item">ホームページアドレス</p>
                                    <Field name="hpAddress" component="input" type="text" className="form-control" />
                                </div>

                                <div>
                                    <p className="req item">事業形態</p>
                                    <Field name="businessForm" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="法人">法人</option>
                                        <option value="個人">個人</option>
                                        <option value="その他(各種)団体等">その他(各種)団体等</option>
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">営業形態</p>
                                    <Field name="salesForm" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="店頭販売">店頭販売</option>
                                        <option value="ネット販売">ネット販売</option>
                                        <option value="店頭・ネット両方">店頭・ネット両方</option>
                                        <option value="催事">催事</option>
                                        <option value="その他">その他</option>
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">当市場を知ったきっかけ</p>
                                    <div className="form-check">
                                        <Field
                                            name="knewReason"
                                            component="input"
                                            type="checkbox"
                                            value="紹介(口コミ)"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label">紹介(口コミ)</label>
                                    </div>
                                    {values.knewReason
                                        ? values.knewReason.includes("紹介(口コミ)") && (
                                              <>
                                                  <p className="unreq item sm">紹介者</p>
                                                  <Field
                                                      name="knewReason_紹介者"
                                                      component="input"
                                                      type="text"
                                                      className="form-control mb-2"
                                                  />
                                              </>
                                          )
                                        : ""}
                                    <div className="form-check">
                                        <Field
                                            name="knewReason"
                                            component="input"
                                            type="checkbox"
                                            value="ネット検索"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label">ネット検索</label>
                                    </div>
                                    <div className="form-check">
                                        <Field
                                            name="knewReason"
                                            component="input"
                                            type="checkbox"
                                            value="リサイクル通信"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label">リサイクル通信</label>
                                    </div>
                                    <div className="form-check">
                                        <Field
                                            name="knewReason"
                                            component="input"
                                            type="checkbox"
                                            value="その他"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label">その他</label>
                                    </div>
                                    {errors.knewReason && <span style={{ color: "red" }}>{errors.knewReason}</span>}
                                </div>

                                <div>
                                    <p className="req item">当市場の利用方法</p>
                                    <Field name="marketUsage" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="売りのみ">売りのみ</option>
                                        <option value="買いのみ">買いのみ</option>
                                        <option value="両方">両方</option>
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">市場参加は初めてですか？</p>
                                    <Field name="isFirstTime" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="初めての参加">初めての参加</option>
                                        <option value="他の市場も参加しています">他の市場も参加しています</option>
                                    </Field>
                                </div>

                                <div>
                                    <p className="unreq item">ご意見・ご要望</p>
                                    <Field name="opinions" component="textarea" className="form-control" />
                                </div>

                                <div>
                                    <button className="link_btn center mt-3" type="submit">
                                        入力内容を確認
                                    </button>
                                </div>
                            </form>
                        )}
                    />

                    {/* 内容確認モーダル */}
                    {isModalOpen && (
                        <div className="checkingModal">
                            <div className="checkingModalContent">
                                <p className="title">入力内容の確認</p>
                                <div className="inputedValues">
                                    <label>見学の予約</label>
                                    <p className="inputedValue">{formData.tourReservation}</p>
                                    {formData.tourReservation === "希望します" && (
                                        <>
                                            <label>見学希望日</label>
                                            <p className="inputedValue">{formData.tourReservationDate}</p>
                                            <label>見学人数</label>
                                            <p className="inputedValue">{formData.tourReservationPersons}</p>
                                        </>
                                    )}
                                    <label>参加の予約</label>
                                    <p className="inputedValue">{formData.entryReservation}</p>
                                    {formData.entryReservation === "希望します" && (
                                        <>
                                            <label>参加希望日</label>
                                            <p className="inputedValue">{formData.entryReservationDate}</p>
                                            <label>参加人数</label>
                                            <p className="inputedValue">{formData.entryReservationPersons}</p>
                                        </>
                                    )}
                                    <label>出品の有無</label>
                                    <p className="inputedValue">{formData.sellHope}</p>
                                    {["有り", "委託"].includes(formData.sellHope) && (
                                        <>
                                            <label>出品数量</label>
                                            <p className="inputedValue">{formData.sellAmount}</p>
                                            <label>ご出品内容</label>
                                            <p className="inputedValue">{formData.sellDetails}</p>
                                            <label>出品票</label>
                                            <p className="inputedValue">{attachedFileName}</p>
                                        </>
                                    )}
                                    <label>社名・屋号</label>
                                    <p className="inputedValue">{formData.companyName}</p>
                                    <label>メールアドレス</label>
                                    <p className="inputedValue">{formData.mailAddress}</p>
                                    <label>代表者名</label>
                                    <p className="inputedValue">{formData.representativeName}</p>
                                    <label>郵便番号</label>
                                    <p className="inputedValue">{formData.postalCode}</p>
                                    <label>住所</label>
                                    <p className="inputedValue">{formData.address}</p>
                                    <label>電話番号</label>
                                    <p className="inputedValue">{formData.phoneNumber}</p>
                                    <label>許可証の発行都道府県</label>
                                    <p className="inputedValue">{formData.permissionPrefectures}</p>
                                    <label>許可証番号</label>
                                    <p className="inputedValue">{formData.permissionNumber}</p>
                                    <label>許可証アップロード</label>
                                    <p className="inputedValue">画像{attachedImages?.length}枚選択中</p>
                                    <label>ご参加担当者名</label>
                                    <p className="inputedValue">{formData.contactorName}</p>
                                    <label>ご担当者の携帯電話番号</label>
                                    <p className="inputedValue">{formData.contactorPhoneNumber}</p>
                                    <label>適格請求事業者ですか？</label>
                                    <p className="inputedValue">{formData.invoice}</p>
                                    {formData.invoice === "はい" && (
                                        <>
                                            <label>登録番号</label>
                                            <p className="inputedValue">{formData.invoiceNumber}</p>
                                        </>
                                    )}
                                    {formData.hpAddress && (
                                        <>
                                            <label>ホームページアドレス</label>
                                            <p className="inputedValue">{formData.hpAddress}</p>
                                        </>
                                    )}
                                    <label>事業形態</label>
                                    <p className="inputedValue">{formData.businessForm}</p>
                                    <label>営業形態</label>
                                    <p className="inputedValue">{formData.salesForm}</p>
                                    {formData.knewReason && formData.knewReason.length > 0 && (
                                        <>
                                            <label>当市場を知ったきっかけ</label>
                                            <ul className="inputedValue">
                                                {formData.knewReason.map((reason, index) => {
                                                    if (reason === "紹介(口コミ)") {
                                                        return (
                                                            <li key={index}>
                                                                {reason}
                                                                {formData.knewReason_紹介者
                                                                    ? `　紹介者「${formData.knewReason_紹介者}」`
                                                                    : ""}
                                                            </li>
                                                        );
                                                    }
                                                    return <li key={index}>{reason}</li>;
                                                })}
                                            </ul>
                                        </>
                                    )}
                                    <label>当市場の利用方法</label>
                                    <p className="inputedValue">{formData.marketUsage}</p>
                                    <label>市場参加は初めてですか？</label>
                                    <p className="inputedValue">{formData.isFirstTime}</p>
                                    {formData.opinions && (
                                        <>
                                            <label>ご意見・ご要望</label>
                                            <p className="inputedValue">{formData.opinions}</p>
                                        </>
                                    )}
                                </div>

                                <div className="bottomBlock mt-3">
                                    <div className="isSending">
                                        {isSending && (
                                            <>
                                                <Loading height="38px" />
                                                <p className="ms-3">送信中...送信完了まで待ちください。</p>
                                            </>
                                        )}
                                        {sendResult !== "" && <p className="sendResult">{parse(sendResult)}</p>}
                                    </div>
                                    <div className="btns">
                                        {isSending ? (
                                            // 送信中
                                            <></>
                                        ) : sendResult !== "" ? (
                                            // 送信完了後
                                            <Link className="link_btn orange mt-2" onClick={closeModal} to="/">
                                                TOPに戻る
                                            </Link>
                                        ) : (
                                            // 送信前
                                            <>
                                                <p className="link_btn orange" onClick={closeModal}>
                                                    戻る
                                                </p>
                                                <p
                                                    className="link_btn green ms-4 me-2"
                                                    onClick={() => {
                                                        console.log("メールを送信中...");
                                                        sendForm(formData, attachedFile, attachedImages, setIsSending);
                                                        setIsSending(true);
                                                    }}
                                                >
                                                    送信する
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <BsToast show={showToast} onClose={toggleToast} messages={toastMessage} />
        </div>
    );

    async function sendForm(formData, file, images, setIsSending) {
        const trans = {
            zero: "ZERO",
            hb: "HB",
        };
        const SUBJECT = `${trans[formData.auctionName]} 入会フォーム`;

        // 見学の予約 = 希望します
        let tourReservation = "";
        if (formData.tourReservation === "希望します") {
            tourReservation += embedFormValue(formData.tourReservationDate, "見学希望日");
            tourReservation += embedFormValue(formData.tourReservationPersons, "見学人数");
        }

        // 参加の予約 = 希望します
        let entryReservation = "";
        if (formData.entryReservation === "希望します") {
            entryReservation += embedFormValue(formData.entryReservationDate, "参加希望日");
            entryReservation += embedFormValue(formData.entryReservationPersons, "参加人数");
        }

        // 出品の有無 = 有り or 委託
        let sellHope = "";
        if (["有り", "委託"].includes(formData.sellHope)) {
            sellHope += embedFormValue(formData.sellAmount, "出品数量");
            sellHope += embedFormValue(formData.sellDetails, "ご出品内容");
            sellHope += embedFormValue("・添付ファイルをご確認ください。", "出品票");
        }

        // 適格請求事業者ですか？ = はい
        let invoiceNumber = "";
        if (formData.invoice === "はい") {
            invoiceNumber += embedFormValue(formData.invoiceNumber, "登録番号");
        }

        // ホームページアドレス
        let hpAddress = embedFormValue(formData.hpAddress, "ホームページアドレス");

        const knewReasons = () => {
            let result = "";
            formData.knewReason.forEach((reason) => {
                if (reason === "紹介(口コミ)") {
                    result += `${reason}${
                        formData.knewReason_紹介者 ? ` - 紹介者「${formData.knewReason_紹介者}」　` : "　　"
                    }`;
                } else {
                    result += reason + "　　";
                }
            });
            return result || "なし";
        };

        let opinions = embedFormValue(formData.opinions, "ご意見・ご要望");

        const buildBody = `
        <html lang='ja'>
        <head>
        <meta charset='UTF-8'>
        </head>
        <body>
            <main>
                <h2>${SUBJECT}</h2>
                <label style="padding: 0; font-weight: bold;">見学の予約</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.tourReservation}</p>
                ${tourReservation}
                <label style="padding: 0; font-weight: bold;">参加の予約</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.entryReservation}</p>
                ${entryReservation}
                <label style="padding: 0; font-weight: bold;">出品の有無</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.sellHope}</p>
                ${sellHope}
                <label style="padding: 0; font-weight: bold;">社名・屋号</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.companyName}</p>
                <label style="padding: 0; font-weight: bold;">メールアドレス</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.mailAddress}</p>
                <label style="padding: 0; font-weight: bold;">代表者名</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.representativeName}</p>
                <label style="padding: 0; font-weight: bold;">郵便番号</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.postalCode}</p>
                <label style="padding: 0; font-weight: bold;">住所</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.address}</p>
                <label style="padding: 0; font-weight: bold;">電話番号</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.phoneNumber}</p>
                <label style="padding: 0; font-weight: bold;">許可証の発行都道府県</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.permissionPrefectures}</p>
                <label style="padding: 0; font-weight: bold;">許可証番号</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.permissionNumber}</p>
                <label style="padding: 0; font-weight: bold;">許可証アップロード</label>
                <p style="margin:0;margin-bottom:10px;">・添付ファイルをご確認ください。</p>
                <label style="padding: 0; font-weight: bold;">ご参加担当者名</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.contactorName}</p>
                <label style="padding: 0; font-weight: bold;">ご担当者の携帯電話番号</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.contactorPhoneNumber}</p>
                <label style="padding: 0; font-weight: bold;">適格請求事業者ですか？</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.invoice}</p>
                ${invoiceNumber}
                ${hpAddress}
                <label style="padding: 0; font-weight: bold;">事業形態</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.businessForm}</p>
                <label style="padding: 0; font-weight: bold;">営業形態</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.salesForm}</p>
                <label style="padding: 0; font-weight: bold;">当市場を知ったきっかけ</label>
                <p style="margin:0;margin-bottom:10px;">・${knewReasons()}</p>
                <label style="padding: 0; font-weight: bold;">当市場の利用方法</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.marketUsage}</p>
                <label style="padding: 0; font-weight: bold;">市場参加は初めてですか？</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.isFirstTime}</p>
                ${opinions}
            </main>
            </body>
            </html>`;

        const formDatas = new FormData();
        formDatas.append("subject", SUBJECT);
        formDatas.append("body", buildBody);

        // 画像以外
        formDatas.append("attachments[]", file);
        // 画像
        for (let i = 0; i < images.length; i++) {
            formDatas.append(`attachments[${i + 1}]`, images[i]);
        }

        try {
            const res = await fetch(`${fetchURL}/sendMail.php`, {
                method: "POST",
                body: formDatas,
            });
            const response = await res.text();

            if (!res.ok) {
                // ステータスコードが200以外の場合
                setSendResult(response);
                throw new Error(response);
            }
            setSendResult(response);
        } catch (error) {
            console.error("エラー:", error);
            setSendResult(error.message);
        } finally {
            setIsSending(false);
        }
    }
}

function getPrefectures() {
    return [
        "北海道",
        "青森",
        "岩手",
        "宮城",
        "秋田",
        "山形",
        "福島",
        "茨城",
        "栃木",
        "群馬",
        "埼玉",
        "千葉",
        "東京",
        "神奈川",
        "新潟",
        "富山",
        "石川",
        "福井",
        "山梨",
        "長野",
        "岐阜",
        "静岡",
        "愛知",
        "三重",
        "滋賀",
        "京都",
        "大阪",
        "兵庫",
        "奈良",
        "和歌山",
        "鳥取",
        "島根",
        "岡山",
        "広島",
        "山口",
        "徳島",
        "香川",
        "愛媛",
        "高知",
        "福岡",
        "佐賀",
        "長崎",
        "熊本",
        "大分",
        "宮崎",
        "鹿児島",
        "沖縄",
    ];
}
