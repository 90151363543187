// utils
import React, { useEffect, useState } from "react";
import {
    loadInputValues,
    saveInputValues,
    dlHandler,
    getParam,
    dateFormater,
    disableBodyScroll,
    embedFormValue,
} from "../assets/funcs/commonFuncs.jsx";
import { useScrollTop } from "../assets/customHooks/useScrollTop";
import parse from "html-react-parser";

// components
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import BsToast from "../components/common/BsToast.jsx";
import Section_title from "../components/common/Section_title";
import Loading from "../components/uncommon/Loading.jsx";
import { fetchURL } from "../assets/customHooks/fetchURL.js";

// 初期値
const LOCAL_STORAGE_KEY = "Form_entry_remind";
// 保存しないフィールド名
const UNSAVE_VALUE_NAMES = ["auctionName", "entryDate", "opinions"];

// 参加予約フォーム
export default function Form_entry({ allSchedules }) {
    useScrollTop();

    // 初期値
    const [initialValueObj, setInitialValueObj] = useState({});

    const [auctionName, setAuctionName] = useState(""); // urlパラメータのオークションネーム
    const [entryDateList, setEntryDateList] = useState([]); // 参加可能な日付のリスト

    // フォームの内容
    const [isModalOpen, setModalOpen] = useState(false); // モーダルの表示状態を管理
    const [formData, setFormData] = useState({}); // フォームデータを保存

    const [attachedFile, setAttachedFile] = useState(null); // 選択されたファイル
    const [attachedFileName, setAttachedFileName] = useState(null); // 選択されたファイル名
    const [attachedFileError, setAttachedFileError] = useState("ファイルが選択されていません");

    // 送信
    const [isSending, setIsSending] = useState(false);
    const [sendResult, setSendResult] = useState("");

    // ファイルが選択された時の処理
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // ファイルが1つで、拡張子がxlsxであるかをチェック
        if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
            setAttachedFile(selectedFile);
            setAttachedFileName(selectedFile.name);
            setAttachedFileError("");
        } else {
            setAttachedFile(null);
            setAttachedFileError("ファイルが選択されていません");
        }
    };

    // 電話番号のフォーマット関数
    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // 数字以外を削除
        const onlyNums = value.replace(/[^\d]/g, "");

        // 3桁-4桁-4桁の形式に変換
        if (onlyNums.length <= 3) return onlyNums;
        if (onlyNums.length <= 7) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7, 11)}`;
    };

    const validateForm = (values) => {
        const errors = {};

        // メールアドレスの一致を確認
        if (values.mailAddress !== values.mailAddress_check) {
            errors.mailAddress_check = "メールアドレスが一致しません。";
        }

        // 電話番号のバリデーション
        if (!values.phoneNumber) {
            errors.phoneNumber = "この項目は必須です";
        } else {
            const onlyNums = values.phoneNumber.replace(/[^\d]/g, "");
            if (onlyNums.length !== 11) {
                errors.phoneNumber = "XXX-XXXX-XXXXの形式で入力してください。";
            }
        }

        return errors;
    };

    const onSubmit = (values) => {
        console.log(values);
        saveInputValues(LOCAL_STORAGE_KEY, values, UNSAVE_VALUE_NAMES);
        setFormData(values);
        disableBodyScroll(true);
        setModalOpen(true);
    };

    // モーダルを閉じる処理
    const closeModal = () => {
        disableBodyScroll(false);
        setModalOpen(false);
    };

    // トースト
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState([]);
    function toggleToast(message) {
        setToastMessage(message);
        setShowToast(!showToast);
    }

    useEffect(() => {
        setInitialValueObj(loadInputValues(LOCAL_STORAGE_KEY));

        const auctionName = getParam("auctionName");
        if (auctionName) {
            setInitialValueObj((prevState) => ({
                ...prevState,
                auctionName: auctionName,
            }));
            setAuctionName(auctionName);
        } else {
            console.warn("auctionNameが取得できませんでした");
        }

        const auctionDate = getParam("date");

        // 参加可能日時リストを取得し初期値を設定する
        const convAuctionName = {
            zero: "zeroMarketSchedules",
            hb: "hbMarketSchedules",
        };

        const EntryDateList = allSchedules[convAuctionName[auctionName]];
        EntryDateList.sort((a, b) => new Date(a.date) - new Date(b.date));
        setEntryDateList(EntryDateList);

        // パラメーターの日時が取得したリストに含まれている場合はそれを選択させる
        const matchingEntryDate = EntryDateList.find((EntryDate) => {
            const rawDate = dateFormater(new Date(EntryDate.date));
            return rawDate === auctionDate;
        });
        if (matchingEntryDate) {
            setInitialValueObj((prevState) => ({
                ...prevState,
                entryDate: dateFormater(new Date(matchingEntryDate.date)),
            }));
        }
    }, [allSchedules]);

    return (
        <div className="page Form" id="Form_entry">
            <main>
                <Section_title
                    eng="Reservation Form"
                    jp={`${auctionName.toUpperCase()}参加予約フォーム`}
                    colorClass={"green"}
                />
                <div className="wrapper">
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialValueObj}
                        validate={validateForm}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="auctionName" component="input" type="hidden" initialValue={auctionName} />
                                <div>
                                    <p className="req item">参加日</p>
                                    <Field name="entryDate" component="select" className="form-select" required>
                                        <option value=""></option>
                                        {entryDateList.map((entryDate, index) => {
                                            const date = dateFormater(entryDate.date);
                                            return (
                                                <option key={index} value={date}>
                                                    {date}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">社名・屋号</p>
                                    <Field
                                        name="companyName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        placeholder="古物商許可証に記載の社名・屋号と同様のものをご記載ください"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">メールアドレス</p>
                                    <Field
                                        name="mailAddress"
                                        component="input"
                                        type="email"
                                        className="form-control"
                                        required
                                    />
                                    <Field name="mailAddress_check" component="input" type="email" required>
                                        {({ input, meta }) => (
                                            <div>
                                                <input className="form-control mt-1" placeholder="確認用" {...input} />
                                                {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">参加人数</p>

                                    {["2名", "3名", "4名以上"].includes(values.entryPersons) && (
                                        <p style={{ color: "red" }}>2名以上の場合は従業員許可が必要</p>
                                    )}
                                    <Field name="entryPersons" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="0名(委託)">0名(委託)</option>
                                        <option value="1名">1名</option>
                                        <option value="2名">2名</option>
                                        <option value="3名">3名</option>
                                        <option value="4名以上">4名以上</option>
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">ご参加担当者名</p>
                                    <Field
                                        name="contactorName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        required
                                    />
                                </div>

                                <div>
                                    <p className="req item">ご担当者の携帯電話番号</p>
                                    <Field
                                        name="phoneNumber"
                                        component="input"
                                        type="text"
                                        placeholder="000-0000-0000"
                                        parse={formatPhoneNumber}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <input className="form-control" {...input} />
                                                {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div>
                                    <p className="req item">出品の有無</p>
                                    <Field name="sellHope" component="select" className="form-select" required>
                                        <option value=""></option>
                                        <option value="無し">無し</option>
                                        <option value="委託">委託</option>
                                        <option value="有り">有り</option>
                                    </Field>
                                </div>

                                {["有り", "委託"].includes(values.sellHope) && (
                                    <>
                                        <div>
                                            <p className="req item">出品数量</p>
                                            <Field
                                                name="sellAmount"
                                                component="input"
                                                type="number"
                                                min="1"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <p className="req item">ご出品内容</p>
                                            <Field
                                                name="sellDetails"
                                                component="textarea"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <p className="req item">出品票アップロード</p>
                                            <p style={{ fontSize: "15px" }}>
                                                出品票ダウンロードは
                                                <span
                                                    className="color_link"
                                                    onClick={() => {
                                                        dlHandler("shuppinhyou");
                                                    }}
                                                >
                                                    こちら
                                                </span>
                                            </p>
                                            <input
                                                name="file"
                                                type="file"
                                                className="form-control"
                                                accept=".xlsx"
                                                required
                                                onChange={handleFileChange}
                                            />
                                            {attachedFileError && <p>ファイルが選択されていません</p>}
                                        </div>
                                    </>
                                )}
                                <div>
                                    <p className="unreq item">ご意見・ご要望</p>
                                    <Field name="opinions" component="textarea" className="form-control" />
                                </div>
                                <div>
                                    <button className="link_btn center mt-3" type="submit">
                                        入力内容を確認
                                    </button>
                                </div>
                            </form>
                        )}
                    />
                    {/* 内容確認モーダル */}
                    {isModalOpen && (
                        <div className="checkingModal">
                            <div className="checkingModalContent">
                                <p className="title">入力内容の確認</p>
                                <div className="inputedValues">
                                    <label>参加日</label>
                                    <p className="inputedValue">{formData.entryDate}</p>
                                    <label>社名・屋号</label>
                                    <p className="inputedValue">{formData.companyName}</p>
                                    <label>メールアドレス</label>
                                    <p className="inputedValue">{formData.mailAddress}</p>
                                    <label>参加人数</label>
                                    <p className="inputedValue">{formData.entryPersons}</p>
                                    <label>ご参加担当者名</label>
                                    <p className="inputedValue">{formData.contactorName}</p>
                                    <label>ご担当者の携帯電話番号</label>
                                    <p className="inputedValue">{formData.phoneNumber}</p>
                                    <label>出品の有無</label>
                                    <p className="inputedValue">{formData.sellHope}</p>
                                    {["有り", "委託"].includes(formData.sellHope) && (
                                        <>
                                            <label>出品数量</label>
                                            <p className="inputedValue">{formData.sellAmount}</p>
                                            <label>ご出品内容</label>
                                            <p className="inputedValue">{formData.sellDetails}</p>
                                            <label>出品票</label>
                                            <p className="inputedValue">{attachedFileName}</p>
                                        </>
                                    )}
                                    {formData.opinions && (
                                        <>
                                            <label>ご意見・ご要望</label>
                                            <p className="inputedValue">{formData.opinions}</p>
                                        </>
                                    )}
                                </div>

                                <div className="bottomBlock mt-3">
                                    <div className="isSending">
                                        {isSending && (
                                            <>
                                                <Loading height="38px" />
                                                <p className="ms-3">送信中...送信完了まで待ちください。</p>
                                            </>
                                        )}
                                        {sendResult !== "" && <p className="sendResult">{parse(sendResult)}</p>}
                                    </div>
                                    <div className="btns">
                                        {isSending ? (
                                            // 送信中
                                            <></>
                                        ) : sendResult !== "" ? (
                                            // 送信完了後
                                            <Link className="link_btn orange mt-2" onClick={closeModal} to="/">
                                                TOPに戻る
                                            </Link>
                                        ) : (
                                            // 送信前
                                            <>
                                                <p className="link_btn orange" onClick={closeModal}>
                                                    戻る
                                                </p>
                                                <p
                                                    className="link_btn green ms-4 me-2"
                                                    onClick={() => {
                                                        console.log("メールを送信中...");
                                                        sendForm(formData, attachedFile, setIsSending, auctionName);
                                                        setIsSending(true);
                                                    }}
                                                >
                                                    送信する
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <BsToast show={showToast} onClose={toggleToast} messages={toastMessage} />
        </div>
    );

    async function sendForm(formData, file, setIsSending) {
        const trans = {
            zero: "ZERO",
            hb: "HB",
        };
        const SUBJECT = `${trans[formData.auctionName]}参加予約フォーム`;

        let sellHope = "";
        if (["有り", "委託"].includes(formData.sellHope)) {
            sellHope += embedFormValue(formData.sellAmount, "出品数量");
            sellHope += embedFormValue(formData.sellDetails, "ご出品内容");
            sellHope += embedFormValue("・添付ファイルをご確認ください。", "出品票");
        }

        let opinions = embedFormValue(formData.opinions, "ご意見・ご要望");

        const buildBody = `
    <html lang='ja'>
        <head>
            <meta charset='UTF-8'>
        </head>
        <body>
            <main>
                <h2>${SUBJECT}</h2>
                <label style="padding: 0; font-weight: bold;">参加日</label>
                <p style="margin:0;margin-bottom:10px;">${formData.entryDate}</p>
                <label style="padding: 0; font-weight: bold;">社名・屋号</label>
                <p style="margin:0;margin-bottom:10px;">${formData.companyName}</p>
                <label style="padding: 0; font-weight: bold;">メールアドレス</label>
                <p style="margin:0;margin-bottom:10px;">${formData.mailAddress}</p>
                <label style="padding: 0; font-weight: bold;">参加人数</label>
                <p style="margin:0;margin-bottom:10px;">${formData.entryPersons}</p>
                <label style="padding: 0; font-weight: bold;">ご参加担当者名</label>
                <p style="margin:0;margin-bottom:10px;">${formData.contactorName}</p>
                <label style="padding: 0; font-weight: bold;">ご担当者の携帯電話番号</label>
                <p style="margin:0;margin-bottom:10px;">${formData.phoneNumber}</p>
                <label style="padding: 0; font-weight: bold;">出品の有無</label>
                <p style="margin:0;margin-bottom:10px;">${formData.sellHope}</p>
                ${sellHope}
                ${opinions}
            </main>
        </body>
    </html>
`;

        const formDatas = new FormData();
        formDatas.append("subject", SUBJECT);
        formDatas.append("body", buildBody);

        // 画像以外
        formDatas.append("attachments[]", file);

        try {
            const res = await fetch(`${fetchURL}/sendMail.php`, {
                method: "POST",
                body: formDatas,
            });
            const response = await res.text();

            if (!res.ok) {
                // ステータスコードが200以外の場合
                setSendResult(response);
                throw new Error(response);
            }
            setSendResult(response);
        } catch (error) {
            console.error("エラー:", error);
            setSendResult(error.message);
        } finally {
            setIsSending(false);
        }
    }
}
