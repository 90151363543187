// utils
import React, { useState } from "react";
import { fetchURL } from "../../assets/customHooks/fetchURL";

// components
import { Cross } from "akar-icons";
import { Form, Field } from "react-final-form";
import Loading from "../uncommon/Loading";
import { dlHandler } from "../../assets/funcs/commonFuncs";

/**
 * 出品票アップローダーコンポーネント　ハンバーガーメニューと出品ページ内から呼び出される。Header内に配置している。
 *
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.visible - アップローダーの表示状態
 * @param {Function} props.toggleShuppinUploaderVisible - アップローダーの表示状態を切り替える関数
 *
 * @returns {JSX.Element} 出品票アップローダーのJSX要素
 */
export default function ShuppinhyouUploader({ visible, toggleShuppinUploaderVisible }) {
    const [attachedFile, setAttachedFile] = useState(null); // 出品票
    const [attachedFileError, setAttachedFileError] = useState("ファイルが選択されていません"); //出品票が選択されていない場合のエラー

    // 送信
    const [isSending, setIsSending] = useState(false);
    const [sendResult, setSendResult] = useState("");

    const closeHandler = () => {
        toggleShuppinUploaderVisible(false);
        setSendResult("");
    };

    // ファイルが1つで、拡張子がxlsxであるかをチェック
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
            setAttachedFile(selectedFile);
            setAttachedFileError("");
        } else {
            setAttachedFile(null);
            setAttachedFileError("ファイルが選択されていません");
        }
    };

    const onSubmit = (values) => {
        console.log(values);
        setIsSending(true);
        sendForm(values, attachedFile, setIsSending);
    };

    return (
        visible && (
            <div id="ShuppinhyouUploader">
                <div className="inner Form">
                    <p className="title">出品票アップロード</p>
                    <div className="closeShuppinhyouUploader" onClick={closeHandler}>
                        <Cross strokeWidth={2} size={20} />
                    </div>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <p className="req item">社名・屋号</p>
                                    <Field
                                        name="companyName"
                                        component="input"
                                        type="text"
                                        className="form-control"
                                        placeholder="古物商許可証に記載の社名・屋号と同様のものをご記載ください"
                                        required
                                    />
                                </div>
                                <div>
                                    <p className="req item">出品数量</p>
                                    <Field
                                        name="sellAmount"
                                        component="input"
                                        type="number"
                                        min="1"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div>
                                    <p className="req item">ご出品内容</p>
                                    <Field name="sellDetails" component="textarea" className="form-control" required />
                                </div>
                                <div>
                                    <p className="req item">出品票アップロード</p>
                                    <p style={{ fontSize: "15px" }}>
                                                出品票ダウンロードは
                                                <span
                                                    className="color_link"
                                                    onClick={() => {
                                                        dlHandler("shuppinhyou");
                                                    }}
                                                >
                                                    こちら
                                                </span>
                                            </p>
                                    <input
                                        name="file"
                                        type="file"
                                        className="form-control"
                                        accept=".xlsx"
                                        required
                                        onChange={handleFileChange}
                                    />
                                    {attachedFileError && <p>ファイルが選択されていません</p>}
                                </div>
                                <div className="btns">
                                    {isSending ? (
                                        <>
                                            <div
                                                className="mt-3"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Loading height="38px" />
                                                <p style={{ textAlign: "center" }}>
                                                    送信中...送信完了まで待ちください。
                                                </p>
                                            </div>
                                        </>
                                    ) : sendResult !== "" ? (
                                        // 送信完了後
                                        <div className="mt-3">
                                            <p className="sendResult center" style={{ textAlign: "center" }}>
                                                {sendResult}
                                            </p>
                                        </div>
                                    ) : (
                                        // 送信前
                                        <>
                                            <button className="link_btn center mt-3" type="submit">
                                                アップロード
                                            </button>
                                        </>
                                    )}
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        )
    );

    /**
     * フォームデータを送信する非同期関数
     *
     * @param {Object} formData - フォームのデータオブジェクト
     * @param {File} file - 添付するファイル
     * @param {Function} setIsSending - 送信中の状態を設定する関数
     * @returns {Promise<void>} 送信処理の完了を示すPromise
     */
    async function sendForm(formData, file, setIsSending) {
        const SUBJECT = `出品票アップロード`;

        const buildBody = `
        <html lang='ja'>
        <head>
        <meta charset='UTF-8'>
        </head>
        <body>
            <main>
                <h2>${SUBJECT}</h2>
                <label style="padding: 0; font-weight: bold;">社名・屋号</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.companyName}</p>
                <label style="padding: 0; font-weight: bold;">出品数量</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.sellAmount}</p>
                <label style="padding: 0; font-weight: bold;">ご出品内容</label>
                <p style="margin:0;margin-bottom:10px;">・${formData.sellDetails}</p>
                <label style="padding: 0; font-weight: bold;">出品票</label>
                <p style="margin:0;margin-bottom:10px;">・添付ファイルをご確認ください。</p>
            </main>
            </body>
            </html>`;

        const formDatas = new FormData();
        formDatas.append("subject", SUBJECT);
        formDatas.append("body", buildBody);
        formDatas.append("attachments[]", file);

        try {
            const result = await fetch(`${fetchURL}/sendMail.php`, {
                method: "POST",
                body: formDatas,
            });
            console.log(await result.text());

            console.log("送信が完了しました。");
            setIsSending(false);
            setSendResult("送信が完了しました。");
        } catch (error) {
            console.error("エラー:", error);
            console.log("送信に失敗しました⚠");
            setIsSending(false);
            setSendResult("送信に失敗しました⚠");
        }
    }
}
